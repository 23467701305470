// https://bhserna.com/remote-modals-with-rails-hotwire-and-bootstrap.html

import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    console.log("ajax-modal#connect")

    this.modal = new Modal(this.element)
    this.modal.show()

    this.element.addEventListener('hidden.bs.modal', this.onHidden)
  }

  disconnect(event) {
    this.element.remove()
  }

  HiddenBeforeRender(event) {
    if (this.isOpen()) {
      event.preventDefault()
      this.element.addEventListener('hidden.bs.modal', event.detail.resume)
      this.modal.hide()
    }
  }

  isOpen() {
    return this.element.classList.contains("show")
  }

  onHidden() {
    this.remove()
  }
}
