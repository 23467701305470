import { Controller } from "@hotwired/stimulus"
// import { Autocomplete } from 'stimulus-autocomplete'

export default class extends Controller {
  connect() {
    console.log("venue_autocomplete#connect")

    this.element.addEventListener('autocomplete.change', (event) => {
      const data = JSON.parse(event.detail.selected.dataset.autocompleteValues)
      const form = event.target.closest('form')

      form.querySelector('#inquiry_address').value = data.address
      form.querySelector('#inquiry_zipcode').value = data.zipcode
      form.querySelector('#inquiry_city').value = data.city
      form.querySelector('#inquiry_venue_description').value = data.venue_description
      form.querySelector('#inquiry_accessible').checked = data.accessible
    })
  }
}
