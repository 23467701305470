import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  checkboxTargets: HTMLInputElement[]
  checkboxAllTarget: HTMLInputElement
  submitButtonTarget: HTMLInputElement
  buttonValue: Number

  static targets: string[] = ['checkbox', 'checkboxAll', 'submitButton']
  static values = { button: String }

  initialize () {
    this.updateCount = this.updateCount.bind(this)
  }

  connect (): void {
    console.log("selected-count#connect")

    this.checkboxAllTarget.addEventListener('change', this.updateCount)
    this.checkboxTargets.forEach(checkbox => checkbox.addEventListener('change', this.updateCount))
  }

  disconnect (): void {
    this.checkboxAllTarget.removeEventListener('change', this.updateCount)
    this.checkboxTargets.forEach(checkbox => checkbox.removeEventListener('change', this.updateCount))
  }

  updateCount (): void {
    const checkboxesCheckedCount = this.checked.length

    if (checkboxesCheckedCount > 0) {
      this.submitButtonTarget.value = `${checkboxesCheckedCount} ${this.buttonValue}`;
      this.enableSubmit()
    } else {
      this.submitButtonTarget.value = this.buttonValue;
      this.disableSubmit()
    }

  }

  enableSubmit (): void {
    this.submitButtonTarget.disabled = false;
  }

  disableSubmit (): void {
    this.submitButtonTarget.disabled = true;
  }

  get checked (): HTMLInputElement[] {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }
}
