import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("radius#connect")
  }

  change(event) {
    console.log("radius#change")

    let value = this.element.querySelector('#musician_radius').value
    let container = this.element.querySelector('#musician_radius_value')

    container.innerHTML = value
  }
}
