import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect (): void {
    console.log("form#connect")

    this.element.addEventListener('turbo:submit-start', this.disable)
  }

  disconnect (): void {
    this.element.removeEventListener('turbo:submit-start', this.disable)
  }

  disable (): void {
    this.classList.add("loading");

    this.querySelectorAll("[type=submit]").forEach(e => {
      e.disabled = true
    })
  }
}
