import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("calender-input#connect")

    import("flatpickr").then(flatpickr => {
      console.log('loading flatpickr')

      const Flatpickr = flatpickr.default

      import("flatpickr/dist/l10n/de").then(flatpickr_de => {
        const input = this.element.querySelectorAll('input')

        Flatpickr(input, {
          locale: "de",
          dateFormat: "d.m.Y"
        });
      });
    });
  }
}
