import { Controller } from "@hotwired/stimulus"
import { Alert } from "bootstrap"

export default class extends Controller {
  connect() {
    console.log("alert#connect")
    var alert = new Alert(this.element)

    setTimeout(function() {
      alert.close();
    }, 2000);
  }
}
