import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("time-input#connect")

    import("flatpickr").then(flatpickr => {
      console.log('loading flatpickr for time')

      const Flatpickr = flatpickr.default

      const input = this.element.querySelectorAll('input')

      Flatpickr(input, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      });
    });
  }
}
